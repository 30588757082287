<template>
  <div>
    <v-container>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="6" xl="4">
          <alert-icons />

          <v-container class="px-0 text-center text-sm-start">
            <!-- Step 1: Email and Password -->
            <v-form
              v-if="!success"
              ref="resetPasswordForm"
              v-model="valid"
              @submit.prevent="resetPassword"
            >
              <h1>{{ $t('resetPassword') }}</h1>
              <p>{{ $t('resetPasswordText') }}</p>
              <v-text-field
                v-model="newPassword"
                :label="$t('password')"
                autofocus
                :rules="passwordRules"
                prepend-icon="fa-lock"
                :type="showPassword ? 'text' : 'password'"
                class="py-10"
              >
                <template #append>
                  <v-btn
                    :key="'showPassword=' + showPassword"
                    icon
                    color="primary"
                    @click="showPassword = !showPassword"
                  >
                    <v-icon v-if="!showPassword">fa-eye</v-icon>
                    <v-icon v-else>fa-eye-slash</v-icon>
                  </v-btn>
                </template>
              </v-text-field>

              <div class="d-flex py-3">
                <v-spacer />
                <v-btn
                  type="submit"
                  color="primary"
                  :loading="loading"
                  :block="$vuetify.breakpoint.xsOnly"
                  :disabled="!valid || loading"
                  @click.prevent="resetPassword"
                >
                  {{ $t('resetPassword') }}
                </v-btn>
              </div>
            </v-form>

            <div v-else class="text-center">
              <div class="d-flex justify-center pa-10">
                <v-icon color="primary" x-large>fa-check-circle</v-icon>
              </div>
              <h1>{{ $t('passwordSet') }}</h1>
              <p>{{ $t('passwordSetMsg') }}</p>
              <v-btn color="primary" to="/login">{{ $t('login') }}</v-btn>
            </div>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import api from '@/util/api';
import { passwordRules } from '@/consts/userRules';
import { translate as t } from '@/util/i18n';
import errorMessage from '@/util/errorMessage';
import AlertIcons from '@/components/AlertIcons';

export default {
  name: 'ResetPassword',

  components: {
    AlertIcons,
  },

  data: () => ({
    success: false,
    valid: true,
    loading: false,
    showPassword: false,
    id: '',
    passwordRules: passwordRules(),
    newPassword: '',
  }),

  mounted() {
    this.id = this.$route.query.id;
  },

  methods: {
    resetPassword() {
      this.$refs.resetPasswordForm.validate();
      if (this.valid) {
        this.loading = true;
        api
          .completeResetPassword({
            code: this.id,
            newPassword: this.newPassword,
          })
          .then(() => {
            this.loading = false;
            this.success = true;
          })
          .catch((res) => {
            this.loading = false;
            if (res.status === 541) {
              errorMessage(t('linkExpired'), t('linkExpiredMsg'), res);
            } else {
              errorMessage(t('passwordResetError'), t('passwordResetErrorMsg'), res);
            }
          });
      }
    },
  },
};
</script>
